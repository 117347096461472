import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// components
// utils
import { Color } from "../../utils/color";

export const BreadcrumbBar = (props) => {
  return (
    <SBar>
      <SContainer>
        {props.breadcrumbs.map(({ txt, url }) => {
          return (
            <SWrapper key={url}>
              <Link to={url}>{txt}</Link>
              <span>/</span>
            </SWrapper>
          );
        })}
      </SContainer>
    </SBar>
  );
};

const SBar = styled.nav`
  height: 48px;
  background-color: ${Color.gray100};
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    height: 32px;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
  /*  */
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: left;
`;

const SWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  &:first-of-type {
    a {
      padding-left: 0;
    }
  }
  span {
    line-height: 1;
    font-size: 0.8rem;
  }
  a {
    line-height: 1;
    font-size: 0.8rem;
    color: ${Color.gray400};
    transition: all 0.2s;
    padding: 0 1rem;
    @media screen and (min-width: 1024px) {
      &:hover {
        color: ${Color.blue};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        color: ${Color.blue};
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    a,
    span {
      font-size: 0.6rem;
    }
  }
`;
