import React from "react";
// components
import { DefaultLayout } from "../components/layout/defaultLayout";
import { PageHeading } from "../components/heading/pageHeading";
import { BreadcrumbBar } from "../components/bar/breadcrumbBar";
import { GenreSection } from "../components/section/genreSection";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "ジャンルから探す", url: "/genre" },
];

const GenrePage = () => {
  return (
    <DefaultLayout pageTitle="ジャンルから探す">
      <PageHeading enTxt="GENRE" jpTxt="ジャンルから探す" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <GenreSection />
    </DefaultLayout>
  );
};

export default GenrePage;
