import React from "react";
import styled from "@emotion/styled";
// utils
import { Color } from "../../utils/color";
// image
import ImgBg from "../../images/cmp_pageheading.jpg";

export const PageHeading = (props) => {
  return (
    <SHeading>
      <span>{props.enTxt}</span>
      <span>{props.jpTxt}</span>
    </SHeading>
  );
};

const SHeading = styled.div`
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${ImgBg});
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  span {
    line-height: 1;
    color: ${Color.white};
    &:first-of-type {
      font-size: 4rem;
      font-weight: 100;
      letter-spacing: 0.5rem;
    }
    &:last-of-type {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 400;
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    height: 160px;
    span {
      &:first-of-type {
        font-size: 3rem;
      }
    }
  }
`;
