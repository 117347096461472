import React from "react";
import styled from "@emotion/styled";
// utils
import { Color } from "../../utils/color";

export const SectionHeading = (props) => {
  return (
    <SHeading>
      <span>{props.txt}</span>
    </SHeading>
  );
};

const SHeading = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1;
    color: ${Color.gray400};
  }
`;
